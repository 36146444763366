<template>
  <div class="theodore-eligibility-checker">
    <transition name="modal" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">What is an STN?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" @click="showModal = false">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h2>You can look for your student's STN in the following places:</h2>
                <ul class="steps">
                  <li class="complete"><strong>Student Tech Dashboard(s)</strong> (most commonly, this will be Canvas or Clever)</li>
                  <li class="complete">Your student's <strong>report card</strong></li>
                  <li class="complete">Your student's <strong>IEP (Individualized Education Plan)</strong>, if applicable</li>
                  <li class="complete">Your student's <strong>504 Plan</strong>, if applicable</li>
                  <li class="complete">Your student's <strong>ILP (Individualized Language Plan)</strong>, if applicable</li>
                  <li class="complete"><strong>Hard copies</strong> of your student's files (available at the school)</li>
                  <li class="complete">Your student's <strong>ILEARN/IREAD/IAM test results</strong></li>
                  <li class="complete">An <strong>attendance report</strong> for your student</li>
                  <li class="complete">Your school's <strong>internal academic documentation</strong></li>
                  <li class="complete">Your <strong>Free/Reduced Meal Service</strong> documents, if applicable</li>
                  <li class="complete">Your <strong>Transportation Routing</strong> documents</li>
                  <li class="complete">Your <strong>student's login</strong> to 1-to-1 device (STN is sometimes used to login)</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="showModal = false">Close & Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="container-fluid p-0" v-if="this.submitted">
      <div class="row">
        <div class="col-md-12">
          <a class="alert alert-warning" v-if="!this.eligible" href="https://www.indianalearns.org/families/">
            The information you provided is not found, review eligibility requirements →
          </a>
          <a class="alert alert-success" v-if="this.eligible && !this.accessed" :href="'https://app.indianalearns.org/esa/esa_parents/new?student_token=' + this.accessToken">
            Eligibility confirmed, click here to claim account. →
          </a>
          <a class="alert alert-success" v-if="this.eligible && this.accessed" href="https://app.indianalearns.org/users/sign_in">
            Your account has been claimed, click to sign in →
          </a>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0" v-if="!this.submitted">
      <div class="row d-none d-lg-block">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="input-group smb-6">
            <input type="text" class="form-control" placeholder="Your Student's STN" v-model="eligibilityEntry.studentIdentifier">
            <date-picker v-model="eligibilityEntry.dateOfBirth" valueType="format" placeholder="Date of Birth"></date-picker>
            <div class="input-group-append" @click="checkEligibility()">
              <span class="input-group-text">Check Eligibility</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-lg-none">
        <div class="col-sm-12">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Your Student's STN" v-model="eligibilityEntry.studentIdentifier">
          </div>
        </div>
      </div>
      <div class="row d-lg-none">
        <div class="col-sm-12">
          <div class="form-group mobile">
            <date-picker v-model="eligibilityEntry.dateOfBirth" valueType="format" placeholder="Date of Birth"></date-picker>
          </div>
        </div>
      </div>
      <div class="row d-lg-none">
        <div class="col-sm-12">
          <button class="btn btn-primary" @click="checkEligibility()">Check Eligibility</button>
        </div>
      </div>
      <span class="error-message" v-if="this.errorMessage.length > 0">
        {{this.errorMessage}}
      </span>
      <a class="sublink" href="#" @click="showModal = true">What is an STN?</a>
    </div>
  </div>
</template>

<script>
  import {_} from 'vue-underscore';
  import axios from 'axios';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'EligibilityWidget',
    components: { DatePicker },
    props: {
    },
    computed: {
    },
    mounted (){
    },
    data() {
      return {
        errorMessage: "",
        eligibilityEntry: {
          studentIdentifier: "",
          dateOfBirth: ""
        },
        submitted: false,
        eligible: false,
        accessed: false,
        accessToken: "",
        showModal: false
      }
    },
    async created() {
    },
    methods: {
      checkEligibility(){
        this.errorMessage = ""

        if (this.eligibilityEntry.studentIdentifier == "" || this.eligibilityEntry.dateOfBirth == ""){
          this.errorMessage = "Please enter an STN & date of birth"
        }

        if (this.errorMessage.length == 0){
          axios
          .get(`https://app.indianalearns.org/api/esa/esa_accounts/find_by_student_information`, {
            params: {
              student_identifier: this.eligibilityEntry.studentIdentifier,
              dob: this.eligibilityEntry.dateOfBirth
            }
          })
          .then((response) => {
            this.submitted = true
            if (response.data) {
              this.eligible = response.data.success
              this.accessed = response.data.already_accessed
              if (!this.accessed) {
                this.accessToken = response.data.token
              }
            } else {
              alert("There was an error checking your information, please try again.")
            }
          })
        }
      }
    }
  }
</script>
