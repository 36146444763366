import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#theodore-eligibility-widget')

import "@/assets/main.scss";
