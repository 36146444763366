<template>
  <EligibilityChecker/>
</template>

<script>
  import EligibilityChecker from './components/EligibilityChecker.vue'

  export default {
    name: 'app',
    components: {
      EligibilityChecker
    }
  }
</script>
